.datepicker-centered {
  display: flex;
  justify-items: center;
  margin: 0 auto 0 auto;
  width: fit-content;
}

.mobile-calendar {
  .react-datepicker__month-container {
    width: 100%;
  }
}
