/* ##### MultiRangeSlider ##### */

.rc-slider-rail {
  height: 8px !important;
  background-color: white !important;
}
.rc-slider-track {
  height: 8px !important;
}
.rc-slider-handle {
  box-shadow: 4px 4px 16px rgba(22, 24, 26, 0.15) !important;
  border-color: gray !important;
  width: 28px !important;
  height: 28px !important;
  margin-top: -10px !important;
  opacity: 100% !important;
}
.rc-slider-handle:focus-visible {
  outline: solid 2px !important;
}

/* ##### End of MultiRangeSlider ##### */
